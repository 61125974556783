<template>
  <div class="recordModule">
    <h2 class="title" style="padding-top: 10px">
      <div class="title_icon"></div>
      <span class="titlecontent">处理记录</span>
    </h2>
    <el-table
      border
      
      :data="tableData"
      style="width: 100% !important;min-height:500px;"
    >
      <el-table-column
        :label="$t('list.index')"
        type="index"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        :label="$t('searchModule.Operation_type')"
        align="center"
        prop="handleTypeName"
      ></el-table-column>
      <el-table-column
        label="操作来源"
        align="center"
        prop="operateSourceName"
      ></el-table-column>
      <el-table-column
        :label="$t('searchModule.Operator')"
        align="center"
        prop="operatorName"
      ></el-table-column>
      <el-table-column
        label="记录信息"
        align="center"
        prop="continuousList"
      >
        <template slot-scope="{ row }">
          <div v-clipboard:copy="row.parkRecordId" v-clipboard:success="onCopy">{{row.continuousList}}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('searchModule.Operation_time')"
        align="center"
        prop="operateDate"
      ></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "processExceptionRecords",
  data() {
    return {};
  },
  watch: {
    // timeInput: {
    //   handler(curVal, oldVal) {
    //     console.log("curVal", curVal);
    //     console.log("oldVal", oldVal);
    //   },
    //   deep: true,
    // },
    // batchType: {
    //   handler(curVal) {
    //     console.log('haha', curVal)
    //   },
    //   deep: true
    // }
  },
  methods: {
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
  },
  props: {
    tableData: {
      default: function () {
        return [1, 2];
      },
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.recordModule {
  margin-bottom 10px;
}
.titlecontent {
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #2F2F51;
}
.el-table>>> .headerTitleS th {
  background: #F3F7FF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
}

.el-table>>> td {
  height: 40px;
  padding: 0px;
  background: #F9FBFF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
}
</style>
